import { v4 as uuid } from "uuid";

const galleryDB = [
  // ===============================================
  // A L’HORIZON
  // ===============================================
  {
    id: uuid(),
    name: "A L’HORIZON",
    paints: [
      // -------------------------------------------
      // INTERIORITES-SAN-DIEGO
      // -------------------------------------------
      {
        id: uuid(),
        name: "INTERIORITES-SAN-DIEGO",
        year: "2020",
        width: 116,
        height: 89,
        technical: "Huile sur toile",
        url: "Horizon/INTERIORITES-SAN-DIEGO.webp",
        url_mobile: "Horizon/mobile/INTERIORITES-SAN-DIEGO.webp",
        previewUrl: "Horizon/thumbnails/INTERIORITES-SAN-DIEGO.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // JUSTE DE PASSAGE
      // -------------------------------------------
      {
        id: uuid(),
        name: "JUSTE DE PASSAGE #3",
        year: "2020",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Horizon/JUSTE-DE-PASSAGE-3.webp",
        url_mobile: "Horizon/mobile/JUSTE-DE-PASSAGE-3.webp",
        previewUrl: "Horizon/thumbnails/JUSTE-DE-PASSAGE-3.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // SVALBARD
      // -------------------------------------------
      {
        id: uuid(),
        name: "SVALBARD",
        year: "2020",
        width: 100,
        height: 80,
        technical: "Huile sur toile",
        url: "Horizon/SVALBARD.webp",
        url_mobile: "Horizon/mobile/SVALBARD.webp",
        previewUrl: "Horizon/thumbnails/SVALBARD.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // TRACK
      // -------------------------------------------
      {
        id: uuid(),
        name: "TRACK #1",
        year: "2023",
        width: 40,
        height: 30,
        technical: "Huile sur toile, broderie",
        url: "Horizon/THE-TRACK-1.webp",
        url_mobile: "Horizon/mobile/THE-TRACK-1.webp",
        previewUrl: "Horizon/thumbnails/THE-TRACK-1.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "TRACK #2",
        year: "2023",
        width: 18,
        height: 23,
        technical: "Huile sur toile, broderie",
        url: "Horizon/THE-TRACK-2.webp",
        url_mobile: "Horizon/mobile/THE-TRACK-2.webp",
        previewUrl: "Horizon/thumbnails/THE-TRACK-2.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "TRACK #3",
        year: "2023",
        width: 24,
        height: 29,
        technical: "Huile sur toile, broderie",
        url: "Horizon/THE-TRACK-3.webp",
        url_mobile: "Horizon/mobile/THE-TRACK-3.webp",
        previewUrl: "Horizon/thumbnails/THE-TRACK-3.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // CONFIN
      // -------------------------------------------
      {
        id: uuid(),
        name: "CONFIN #1",
        year: "2023",
        width: 35,
        height: 45,
        technical: "Huile sur toile, broderie",
        url: "Horizon/CONFIN-1.webp",
        url_mobile: "Horizon/mobile/CONFIN-1.webp",
        previewUrl: "Horizon/thumbnails/CONFIN-1.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "CONFIN #2",
        year: "2023",
        width: 35,
        height: 50,
        technical: "Huile sur toile, broderie",
        url: "Horizon/CONFIN-2.webp",
        url_mobile: "Horizon/mobile/CONFIN-2.webp",
        previewUrl: "Horizon/thumbnails/CONFIN-2.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "CONFIN #3",
        year: "2023",
        width: 35,
        height: 45,
        technical: "Huile sur toile, broderie",
        url: "Horizon/CONFIN-3.webp",
        url_mobile: "Horizon/mobile/CONFIN-3.webp",
        previewUrl: "Horizon/thumbnails/CONFIN-3.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // APPEARANCE
      // -------------------------------------------
      {
        id: uuid(),
        name: "APPEARANCE",
        year: "2023",
        width: 35,
        height: 42,
        technical: "Huile sur toile, broderie",
        url: "Horizon/APPEARANCE.webp",
        url_mobile: "Horizon/mobile/APPEARANCE.webp",
        previewUrl: "Horizon/thumbnails/APPEARANCE.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // APPEL DE LA FORET
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "APPEL DE LA FORET #1",
        year: "2023",
        width: 40,
        height: 50,
        technical: "Huile sur toile, broderie",
        url: "Horizon/APPEL-DE-LA-FORET-1.webp",
        url_mobile: "Horizon/mobile/APPEL-DE-LA-FORET-1.webp",
        previewUrl: "Horizon/thumbnails/APPEL-DE-LA-FORET-1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "APPEL DE LA FORET #2",
        year: "2023",
        width: 40,
        height: 50,
        technical: "Huile sur toile, broderie",
        url: "Horizon/APPEL-DE-LA-FORET-2.webp",
        url_mobile: "Horizon/mobile/APPEL-DE-LA-FORET-2.webp",
        previewUrl: "Horizon/thumbnails/APPEL-DE-LA-FORET-2.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // A L'AULNE
      // -------------------------------------------
      {
        id: uuid(),
        name: "A L'AULNE",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/A-L-AULNE.webp",
        url_mobile: "Horizon/mobile/A-L-AULNE.webp",
        previewUrl: "Horizon/thumbnails/A-L-AULNE.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // LAPONIE, presque rien
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "LAPONIE, presque rien #1",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/LAPONIE-1-PRESQUE-RIEN.webp",
        url_mobile: "Horizon/mobile/LAPONIE-1-PRESQUE-RIEN.webp",
        previewUrl: "Horizon/thumbnails/LAPONIE-1-PRESQUE-RIEN.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LAPONIE, presque rien #2",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/LAPONIE-2-PRESQUE-RIEN.webp",
        url_mobile: "Horizon/mobile/LAPONIE-2-PRESQUE-RIEN.webp",
        previewUrl: "Horizon/thumbnails/LAPONIE-2-PRESQUE-RIEN.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LAPONIE, presque rien #3",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/LAPONIE-3-PRESQUE-RIEN.webp",
        url_mobile: "Horizon/mobile/LAPONIE-3-PRESQUE-RIEN.webp",
        previewUrl: "Horizon/thumbnails/LAPONIE-3-PRESQUE-RIEN.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LAPONIE, presque rien #4",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/LAPONIE-4-PRESQUE-RIEN.webp",
        url_mobile: "Horizon/mobile/LAPONIE-4-PRESQUE-RIEN.webp",
        previewUrl: "Horizon/thumbnails/LAPONIE-4-PRESQUE-RIEN.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LAPONIE, presque rien #5",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/LAPONIE-5-PRESQUE-RIEN.webp",
        url_mobile: "Horizon/mobile/LAPONIE-5-PRESQUE-RIEN.webp",
        previewUrl: "Horizon/thumbnails/LAPONIE-5-PRESQUE-RIEN.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LAPONIE, presque rien #6",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/LAPONIE-6-PRESQUE-RIEN.webp",
        url_mobile: "Horizon/mobile/LAPONIE-6-PRESQUE-RIEN.webp",
        previewUrl: "Horizon/thumbnails/LAPONIE-6-PRESQUE-RIEN.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // MONUMENT VALLEY
      // -------------------------------------------
      {
        id: uuid(),
        name: "MONUMENT VALLEY",
        year: "2022",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Horizon/MONUMENT-VALLEY.webp",
        url_mobile: "Horizon/mobile/MONUMENT-VALLEY.webp",
        previewUrl: "Horizon/thumbnails/MONUMENT-VALLEY.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // LIBERTÉ DORÉE
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "LIBERTÉ DORÉE",
        year: "2022",
        width: 50,
        height: 40,
        technical: "Huile sur toile",
        url: "Horizon/LIBERTÉ-DORÉE.webp",
        url_mobile: "Horizon/mobile/LIBERTÉ-DORÉE.webp",
        previewUrl: "Horizon/thumbnails/LIBERTÉ-DORÉE.webp",
        price: 0,
        currency: "€",
      },*/
    ],
  },

  // ===============================================
  // A L’ENVOL
  // ===============================================
  {
    id: uuid(),
    name: "A L’ENVOL",
    paints: [
      // -------------------------------------------
      // ALONE
      // -------------------------------------------
      {
        id: uuid(),
        name: "ALONE",
        year: "2023",
        width: 38,
        height: 45,
        technical: "Huile sur toile, broderie",
        url: "Envol/ALONE.webp",
        url_mobile: "Envol/mobile/ALONE.webp",
        previewUrl: "Envol/thumbnails/ALONE.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // HUNTING
      // -------------------------------------------
      {
        id: uuid(),
        name: "ON THE HUNTING",
        year: "2023",
        width: 44,
        height: 31,
        technical: "Huile sur toile, broderie",
        url: "Envol/HUNTING.webp",
        url_mobile: "Envol/mobile/HUNTING.webp",
        previewUrl: "Envol/thumbnails/HUNTING.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // MEUTE
      // -------------------------------------------
      {
        id: uuid(),
        name: "MEUTE",
        year: "2023",
        width: 38,
        height: 45,
        technical: "Huile sur toile, broderie",
        url: "Envol/MEUTE.webp",
        url_mobile: "Envol/mobile/MEUTE.webp",
        previewUrl: "Envol/thumbnails/MEUTE.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // ENJOY THE SILENCE
      // -------------------------------------------
      {
        id: uuid(),
        name: "ENJOY THE SILENCE #1",
        year: "2023",
        width: 89,
        height: 116,
        technical: "Huile sur toile",
        url: "Envol/ENJOY-THE-SILENCE1.webp",
        url_mobile: "Envol/mobile/ENJOY-THE-SILENCE-1.webp",
        previewUrl: "Envol/thumbnails/ENJOY-THE-SILENCE-1.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "ENJOY THE SILENCE #2",
        year: "2023",
        width: 50,
        height: 50,
        technical: "Huile sur toile",
        url: "Envol/ENJOY-THE-SILENCE2.webp",
        url_mobile: "Envol/mobile/ENJOY-THE-SILENCE-2.webp",
        previewUrl: "Envol/thumbnails/ENJOY-THE-SILENCE-2.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "ENJOY THE SILENCE #3",
        year: "2023",
        width: 50,
        height: 50,
        technical: "Huile sur toile",
        url: "Envol/ENJOY-THE-SILENCE3.webp",
        url_mobile: "Envol/mobile/ENJOY-THE-SILENCE-3.webp",
        previewUrl: "Envol/thumbnails/ENJOY-THE-SILENCE-3.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // LE PASSAGE DE LA NUIT
      // -------------------------------------------
      {
        id: uuid(),
        name: "LE PASSAGE DE LA NUIT #2",
        year: "2021",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Envol/PASSAGE2.webp",
        url_mobile: "Envol/mobile/PASSAGE2.webp",
        previewUrl: "Envol/thumbnails/PASSAGE2.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LE PASSAGE DE LA NUIT #1",
        year: "2021",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Envol/PASSAGE1.webp",
        url_mobile: "Envol/mobile/PASSAGE1.webp",
        previewUrl: "Envol/thumbnails/PASSAGE1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "LE PASSAGE DE LA NUIT #7",
        year: "2021",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Envol/PASSAGE7.webp",
        url_mobile: "Envol/mobile/PASSAGE7.webp",
        previewUrl: "Envol/thumbnails/PASSAGE7.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // IVALO D'EN HAUT
      // -------------------------------------------
      {
        id: uuid(),
        name: "IVALO D'EN HAUT",
        year: "2023",
        width: 44,
        height: 38,
        technical: "Huile sur toile, broderie",
        url: "Envol/IVALO-HAUT.webp",
        url_mobile: "Envol/mobile/IVALO-HAUT.webp",
        previewUrl: "Envol/thumbnails/IVALO-HAUT.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // CROSSING
      // -------------------------------------------
      {
        id: uuid(),
        name: "CROSSING",
        year: "2022",
        width: 50,
        height: 40,
        technical: "Huile sur toile",
        url: "Envol/CROSSING.webp",
        url_mobile: "Envol/mobile/CROSSING.webp",
        previewUrl: "Envol/thumbnails/CROSSING1.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // CROSSING, TRYPTIQUE
      // -------------------------------------------
      {
        id: uuid(),
        name: "CROSSING, TRYPTIQUE PART 1",
        year: "2021",
        width: 89,
        height: 116,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-TRYPTIQUE-PART1.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-TRYPTIQUE-PART1.webp",
        previewUrl: "Envol/thumbnails/CELESTES-CONFINS-TRYPTIQUE-PART1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "CROSSING, TRYPTIQUE PART 2",
        year: "2021",
        width: 89,
        height: 116,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-TRYPTIQUE-PART2.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-TRYPTIQUE-PART2.webp",
        previewUrl: "Envol/thumbnails/CELESTES-CONFINS-TRYPTIQUE-PART2.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "CROSSING, TRYPTIQUE PART 3",
        year: "2021",
        width: 89,
        height: 116,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-TRYPTIQUE-PART3.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-TRYPTIQUE-PART3.webp",
        previewUrl: "Envol/thumbnails/CELESTES-CONFINS-TRYPTIQUE-PART3.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // CÉLESTE NUIT DE LAPONIE
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "CÉLESTE NUIT DE LAPONIE #1",
        year: "2022",
        width: 50,
        height: 40,
        technical: "Huile sur toile",
        url: "Envol/CELESTE-NUIT-DE-LAPONIE-1.webp",
        url_mobile: "Envol/mobile/CELESTE-NUIT-DE-LAPONIE-1.webp",
        previewUrl: "Envol/thumbnails/CELESTE-NUIT-DE-LAPONIE-1.webp",
        price: 0,
        currency: "€",
      },*/
      {
        id: uuid(),
        name: "CÉLESTE NUIT DE LAPONIE #2",
        year: "2022",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Envol/CELESTE-NUIT-DE-LAPONIE-2.webp",
        url_mobile: "Envol/mobile/CELESTE-NUIT-DE-LAPONIE-2.webp",
        previewUrl: "Envol/thumbnails/CELESTE-NUIT-DE-LAPONIE-2.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // CÉLESTE CONFINS
      // -------------------------------------------
      {
        id: uuid(),
        name: "CÉLESTE CONFINS #1",
        year: "2022",
        width: 116,
        height: 89,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-1.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-1.webp",
        previewUrl: "Envol/thumbnails/CELESTES-CONFINS-1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "CÉLESTE CONFINS #2",
        year: "2022",
        width: 50,
        height: 40,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-2.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-2.webp",
        previewUrl: "Envol/thumbnails/CELESTES-CONFINS-2.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "CÉLESTE CONFINS #3",
        year: "2022",
        width: 50,
        height: 40,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-3.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-3.webp",
        previewUrl: "Envol/thumbnails/CELESTES-CONFINS-3.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // CÉLESTE HATTFJELLDAL
      // -------------------------------------------
      {
        id: uuid(),
        name: "CÉLESTE CONFINS HATTFJELLDAL",
        year: "2022",
        width: 130,
        height: 97,
        technical: "Huile sur toile",
        url: "Envol/CELESTES-CONFINS-Hattfjelldal_Norvege.webp",
        url_mobile: "Envol/mobile/CELESTES-CONFINS-Hattfjelldal_Norvege.webp",
        previewUrl: "Envol/thumbnails/celestes-confins-hattfjelldal.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // LA BUSE
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "LA BUSE",
        year: "2023",
        width: 40,
        height: 50,
        technical: "Huile sur toile, broderie",
        url: "Envol/LA-BUSE.webp",
        url_mobile: "Envol/mobile/LA-BUSE.webp",
        previewUrl: "Envol/thumbnails/LA-BUSE.webp",
        price: 0,
        currency: "€",
      },*/
    ],
  },

  // ===============================================
  // APOCALYPSE
  // ===============================================
  {
    id: uuid(),
    name: "APOCALYPSE",
    paints: [
      // -------------------------------------------
      // WATER LILIES LATER
      // -------------------------------------------
      {
        id: uuid(),
        name: "WATER LILIES LATER #4",
        year: "2023",
        width: 30,
        height: 30,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-4.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-4.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-4.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #5",
        year: "2023",
        width: 39,
        height: 32,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-5.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-5.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-5.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #6",
        year: "2023",
        width: 30,
        height: 30,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-6.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-6.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-6.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #1",
        year: "2022",
        width: 116,
        height: 89,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-1.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-1.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-1.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #10",
        year: "2023",
        width: 39,
        height: 32,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-10.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-10.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-10.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #7",
        year: "2023",
        width: 90,
        height: 90,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-7.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-7.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-7.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #2",
        year: "2022",
        width: 116,
        height: 89,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-2.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-2.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-2.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #8",
        year: "2023",
        width: 100,
        height: 80,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-8.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-8.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-8.webp",
        price: 0,
        currency: "€",
      },

      {
        id: uuid(),
        name: "WATER LILIES LATER #3",
        year: "2022",
        width: 116,
        height: 89,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-3.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-3.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-3.webp",
        price: 0,
        currency: "€",
      },

      /*{
        id: uuid(),
        name: "WATER LILIES LATER #11",
        year: "2023",
        width: 25,
        height: 17,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-11.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-11.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-11.webp",
        price: 0,
        currency: "€",
      },*/

      {
        id: uuid(),
        name: "WATER LILIES LATER #9",
        year: "2023",
        width: 90,
        height: 90,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-9.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-9.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-9.webp",
        price: 0,
        currency: "€",
      },

      // -------------------------------------------
      // IVALO
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "IVALO #1",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/IVALO-1.webp",
        url_mobile: "Apocalypse/mobile/IVALO-1.webp",
        previewUrl: "Apocalypse/thumbnails/IVALO-1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "IVALO #2",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/IVALO-2.webp",
        url_mobile: "Apocalypse/mobile/IVALO-2.webp",
        previewUrl: "Apocalypse/thumbnails/IVALO-2.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "IVALO #3",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/IVALO-3.webp",
        url_mobile: "Apocalypse/mobile/IVALO-3.webp",
        previewUrl: "Apocalypse/thumbnails/IVALO-3.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // WATER LILIES
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "WATER LILIES LATER #3",
        year: "2021",
        width: 116,
        height: 89,
        technical: "Huile sur toile",
        url: "Apocalypse/WATER-LILIES-LATER-3.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-3.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-3.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "WATER LILIES LATER #4",
        year: "2022",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Apocalypse/WATER-LILIES-LATER-4.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-4.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-4.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // MILLE MORCEAUX
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "MILLE MORCEAUX #1",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/MILLE-MORCEAUX-1.webp",
        url_mobile: "Apocalypse/mobile/MILLE-MORCEAUX-1.webp",
        previewUrl: "Apocalypse/thumbnails/MILLE-MORCEAUX-1.webp",
        price: 0,
        currency: "€",
      },*/
      /*{
        id: uuid(),
        name: "MILLE MORCEAUX #2",
        year: "2023",
        width: 40,
        height: 30,
        technical: "Huile sur toile",
        url: "Apocalypse/MILLE-MORCEAUX-2.webp",
        url_mobile: "Apocalypse/mobile/MILLE-MORCEAUX-2.webp",
        previewUrl: "Apocalypse/thumbnails/MILLE-MORCEAUX-2.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // PRISON DORÉE
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "PRISON DORÉE #1",
        year: "2023",
        width: 50,
        height: 50,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/PRISON-DOREE-1.webp",
        url_mobile: "Apocalypse/mobile/PRISON-DOREE-1.webp",
        previewUrl: "Apocalypse/thumbnails/PRISON-DOREE-1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "PRISON DORÉE #2",
        year: "2023",
        width: 50,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/PRISON-DOREE-2.webp",
        url_mobile: "Apocalypse/mobile/PRISON-DOREE-2.webp",
        previewUrl: "Apocalypse/thumbnails/PRISON-DOREE-2.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // DERRIÈRE LE ROCHER
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "DERRIÈRE LE ROCHER #1",
        year: "2023",
        width: 30,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/DERRIEERE-LE-ROCHER-1.webp",
        url_mobile: "Apocalypse/mobile/DERRIEERE-LE-ROCHER-1.webp",
        previewUrl: "Apocalypse/thumbnails/DERRIERE-LE-ROCHER-1.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "DERRIÈRE LE ROCHER #2",
        year: "2023",
        width: 30,
        height: 40,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/DERRIEERE-LE-ROCHER-2.webp",
        url_mobile: "Apocalypse/mobile/DERRIEERE-LE-ROCHER-2.webp",
        previewUrl: "Apocalypse/thumbnails/DERRIERE-LE-ROCHER-2.webp",
        price: 0,
        currency: "€",
      },*/

      // -------------------------------------------
      // NENUPHARS PLUS TARD
      // -------------------------------------------
      /*{
        id: uuid(),
        name: "NENUPHARS PLUS TARD #1",
        year: "2023",
        width: 116,
        height: 89,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/WATER-LILIES-LATER-3.webp",
        url_mobile: "Apocalypse/mobile/WATER-LILIES-LATER-3.webp",
        previewUrl: "Apocalypse/thumbnails/WATER-LILIES-LATER-3.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "NENUPHARS PLUS TARD #2",
        year: "2023",
        width: 116,
        height: 89,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/NENUPHARS-PLUS-TARD-2.webp",
        url_mobile: "Apocalypse/mobile/NENUPHARS-PLUS-TARD-2.webp",
        previewUrl: "Apocalypse/thumbnails/NENUPHARS-PLUS-TARD-2.webp",
        price: 0,
        currency: "€",
      },
      {
        id: uuid(),
        name: "NENUPHARS PLUS TARD #3",
        year: "2023",
        width: 116,
        height: 89,
        technical: "Huile sur toile, broderie",
        url: "Apocalypse/NENUPHARS-PLUS-TARD-3.webp",
        url_mobile: "Apocalypse/mobile/NENUPHARS-PLUS-TARD-3.webp",
        previewUrl: "Apocalypse/thumbnails/NENUPHARS-PLUS-TARD-3.webp",
        price: 0,
        currency: "€",
      },*/
    ],
  },

  // ===============================================
  // AMERICAN DOLLAR
  // ===============================================
  /*{
    id: uuid(),
    name: "AMERICAN DOLLAR",
    paints: [
      {
        id: uuid(),
        name: "IVALO",
        year: "2022",
        width: 50,
        height: 40, 
        technical: "Huile sur toile",
        url: "Apocalypse/IVALO.webp",
        url_mobile: 
        previewUrl: "Apocalypse/thumbnails/IVALO.webp",
        price: 0,
        currency: "€",
      },
    ],
  },*/
];

export default galleryDB;
